export const options = [
  {
    label: 'id',
    prop: 'Id',
    width: 70
  },
  {
    label: '用户id',
    prop: 'UserId',
    width: 70
  },
  {
    label: '用户钱包地址',
    prop: 'UserAddress',
    width: 390
  },
  {
    label: '购买金额',
    prop: 'PurchaseAmount',
    width: 120
  },
  {
    label: '交易状态',
    prop: 'Status',
    width: 120
  },
  {
    label: '交易哈希',
    prop: 'TradeHash',
    hide: true
  },
  {
    label: '日利率',
    prop: 'InterestRate',
    width: 120
  },
  {
    label: '创建时间',
    prop: 'CreatedAt'
  },
  {
    label: '操作',
    prop: 'action',
    hide: false
  }
]
