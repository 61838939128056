import request from './request'

export const investments = (params) => {
  return request({
    url: 'investment/list',
    params
  })
}

export const investmentRecord = (params) => {
  return request({
    url: 'investment/purchaseRecord',
    params
  })
}

export const creatInvestment = (params) => {
  return request({
    url: 'investment/creat',
    method: 'POST',
    params
  })
}

export const updateInvestment = (params) => {
  return request({
    url: 'investment/update',
    method: 'POST',
    params
  })
}
export const deleteInvestment = (params) => {
  return request({
    url: 'investment/delete',
    method: 'POST',
    params
  })
}
export const getEarning = (params) => {
  return request({
    url: 'investment/getEarning',
    params
  })
}
